import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('warehouse?page=' + page, {params});
    }, 
    show(id) {
        return Service().get('warehouse/show/'+id);
    },    
    create(params) {
        return Service().post('warehouse/create', params);
    },
    update(id, params) {
        return Service().post('warehouse/update/'+id, params);
    },
    delete(id) {
        return Service().post('warehouse/delete/'+id);
    },
    storageType(page, params){
        return Service().get('warehouse/storage-type?page='+ page, {params})
    },
    storageSection(page, params){
        return Service().get('warehouse/storage-section?page='+ page,{params})
    },
    storageBin(page, params){
        return Service().get('warehouse/storage-bin?page='+ page,{params})
    }
}