<template>
    <div>
        <div v-if="authUserPermission['storage-type-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-7">
                                <h3 class="mb-0">{{ tt('storage_type') }}</h3>
                            </div>
                                <div class="col-5">
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search')" v-on:keyup="filter"/>                            
                                    </div>
                                </div>
                           <!--  <div class="col text-right">
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['warehouse-create']">{{ tt('add_new') }}</base-button>
                            </div> -->
                        </div>
                    </div>
                    <div>
                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('warehouse_number')" :prop="tt('warehouse_number')" sortable>
                                <template v-slot="{row}">
                                    {{row.warehouse_number}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('storage_type_indicator')" :prop="tt('storage_type_indicator')" sortable>
                                <template v-slot="{row}">
                                    {{row.storage_type_indicator}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('description')" :prop="tt('stype_description')" sortable>
                                <template v-slot="{row}">
                                    {{row.stype_description}}
                                </template>
                            </el-table-column>
                     <!--        <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['warehouse-update'] || authUserPermission['warehouse-delete']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['warehouse-update']">{{ tt('edit') }}</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['warehouse-delete']">{{ tt('delete') }}</el-dropdown-item>                                        
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column> -->
                        </el-table>
                        <skeleton-loading v-else/>
                    </div>           
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                              
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import warehouse from '@/services/master/warehouse.service';

    export default {        
        data() {
            return {               
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },              
                form: {
                    add: true,
                    title: this.tt('add_warehouse'),
                    show: false
                },
                table: {
                    search: (this.$route.query.search) ? this.$route.query.search : '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },     
                errorMessage: null,   
                warehouse: {
                    
                },
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, warehouse.storageType(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;  
                    context.table.page  = response.data.data.data;  
                    context.onLoad = false;          
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            filter() {
                if(this.table.search != this.$route.query.search){
                    this.onLoad = true;
                    this.table.page.current_page = 1;
                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.table.search == ''){
                            this.$router.push({path:'/master/storage-type', query:null})
                        }else{
                            this.$router.push({ path: '/master/storage-type', query:{search: this.table.search}})
                        }
                        this.get()
                    }, 100);
                }
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
<style></style>
